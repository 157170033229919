@import url("https://fonts.googleapis.com/css2?family=Gasoek+One&family=Jost:wght@100..900&display=swap");

:root {
      --dark: #211416;
      --main: #070405;
      --main600: #0d080a;
      --main500: #140c10;
      --main400: #1a1015;
      --main300: #211419;
      --main200: #43444d;
      --main100: #72737a;
      --main50: #a1a1a6;

      --darkRgba: rgba(33, 20, 22, .7);

  --accentMain: #cbe934;
  --accentSecondary: #22a0b6;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: var(--main);
  color: #E9EAEC;
}


body, #root {
  min-height: 100vh;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

form {
  background: #ffffff;
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  letter-spacing: 1.3px;

}


.logo-title {
  letter-spacing: 2.5px;
  text-transform: uppercase;
  text-align: center;
  
}

.my-list-link {
  font-size: .9rem;
  letter-spacing: 2.5px;
  text-transform: uppercase;
}

.gradient-text {
  background: -webkit-linear-gradient(120deg, #7b5fac, #f7c978);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
}

.MuiContainer-root {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.css-h4y409-MuiList-root {
  height: 100%;
  background: var(--main);
  padding: 1rem!important;
}

.modal {
  background: var(--darkRgba);
}

.modal .MuiDialog-paper {
  background: var(--main);
}

.modal p, .modal h2, .modal h3, .modal h4, .modal h5, .modal h6 {
  color: #ffffff;
}

.css-1hp60fq {
  background-color: var(--dark)!important;
}


.css-1gdepfy-MuiInputBase-root-MuiOutlinedInput-root, .MuiInputBase-root, .MuiOutlinedInput-root {
  border: .1px solid transparent!important;
}

.css-1gdepfy-MuiInputBase-root-MuiOutlinedInput-root:hover, .MuiInputBase-root:hover, .MuiOutlinedInput-root:hover {
  border: .1px solid var(--main)!important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  color: var(--dark)!important;
}


@media screen and (max-width: 768px) {
  .MuiContainer-root {
      padding-top: 2rem;
      padding-bottom: 2rem;
  }
  .css-rmcv22 {
    font-size: 1.5rem!important;
  }
  .css-1666g5d {
    font-size: 1.2rem!important;
  }
}

/* cookie modal */
.cookie-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left:0;
  right: 0;
  background: var(--darkRgba);


  display: flex;
  justify-items: flex-end;
  align-items: end;
  justify-content: end;
}
.cookie-block {
  max-width: 60%;
  width: 100%;
  border-radius: 16px;
  background-color: #ffffffe4;
  color: var(--dark);
  padding: 1.25rem;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  z-index: 100;
  margin: 1rem;
}

@media screen and (max-width: 1020px) {
  .cookie-block {
    max-width: 90%;
  }
}

.cookie-text {
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

.cookie-accept {
  transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: var(--main);
  color: #ffffff;
  border-radius: 16px;
  border: 3px solid var(--main);
  border: 0;
  padding: 0.6rem 1rem;
  font-size: 1.1rem;
  font-weight: 900;
  cursor: pointer; 
  display: block;
  width: 100%;
  margin-bottom: .5rem;
}


.cookie-more {
  box-sizing: border-box;
  color: var(--main);
  font-size: 1rem;
  border: 2px solid var(--main);
  text-decoration: none;
  font-weight: 800;
  padding: 0.5rem 1rem;
  display: block;
  border-radius: 16px;
  text-align: center;
}


.cookie-close-wrap {
  border-radius: 0.625rem;
  position: absolute;
  bottom: -5%;
  right: 1%;
  transform: translateY(-50%);
}

.cookie-close-btn {
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 0.25rem 1rem;
  margin-left: 0.625rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: var(--main100)
}

.cookie-close-btn:hover {
  transform: scale(1.25);
}

/* cookie modal */

.no-money-disclaimer {
  position: fixed;
  bottom: 0;
  left: 0;
  color: #E9EAEC; /* #E9EAEC text color */
  padding: 10px;
  display: flex;
  align-items: center;
}

.nomoney-icon {
  width: 20px;
  margin-right: 1rem;
}

.message {
  font-size: 14px;
  font-weight: 900;
  margin-left: 1rem;
  text-shadow: 3px 3px 2px rgba(128, 0, 0, 1);
}
